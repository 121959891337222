export const getCommissionTestApi = () => {
  return new Promise(resolve => setTimeout(() => {
    resolve([
      { name: '佣金项目1', id: '1' },
      { name: '佣金项目2', id: '2' },
      { name: '佣金佣金佣金佣金项目3', id: '3' },
      { name: '佣金项目4', id: '4' },
      { name: '佣金项目5', id: '5' },
      { name: '佣金项目6', id: '6' },
      { name: '佣金项目7', id: '7' },
      { name: '佣金项目8', id: '8' },
      { name: '佣金项目9', id: '9' },
      { name: '佣金项目10', id: '10' },
      { name: '佣金项目目目目11', id: '11' },
      { name: '佣金项目12', id: '12' },
      { name: '佣金项目13', id: '13' },
      { name: '佣金项金项金项金项金项目14', id: '14' },
    ]);
  }, 123));
};

export const getRunningWaterPageDataTestApi = (params = {}) => {
  console.log(params)

  return new Promise(resolve => {
    const total = 33;
    let { current: page = 1, size = 10 } = params;
    const remainder = total % size;
    const pages = parseInt(total / size + '') + (remainder ? 1 : 0);
    page = page > 0 ? page : 1;
    let l = page < pages ? size :
      page === pages ? remainder : 0;
    let list = [];
    const flag = () => Math.random() > .5;
    while (l--) {
      let id = new Date().getTime() + '-' + l;
      let isWithdraw = flag();
      list.unshift({
        id,
        isWithdraw, // + -
        title: isWithdraw ? '提现' : '转发红包',
        commission:  parseInt(Math.random() * 5 + 1 + '', 10),
        money: (isWithdraw ? '-' : '') + parseInt(Math.random() * 99999 + 1 + '', 10),
        time: '2022年12月12日 12:12'
      });
    }

    let data = {
      countId: null,
      current: page,
      hitCount: false,
      maxLimit: null,
      optimizeCountSql: true,
      orders: [],
      pages: pages,
      records: list,
      searchCount: true,
      size: size,
      total: total,
    };
    // TODO: XHR
    // console.log(params, JSON.parse(JSON.stringify(data)));

    setTimeout(() => {
      resolve(data);
    }, 321);
  });
};
