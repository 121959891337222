<!-- 流水 -->
<template>
  <div class="running-water-container">
    <div class="search-form">
      <van-tabs
          ref="commissionTabs"
          v-model="searchForm.commissionActiveIndex"
          :line-width="lineWidth"
          @change="onRefresh"
      >
        <van-tab
            v-for="{ name, id } in allCommissionTypeList"
            :key="id"
            :title="name"
        />
      </van-tabs>
      <div class="date-search">
        <div class="date-search-item"
             @click="() => {
               dateTimePickerOpts.vModel = new Date(this.searchForm.time)
               dateTimePickerOpts.show = true
             }"
        >
          {{ searchTimeText }}
          <van-icon name="arrow-down"/>
        </div>
        <van-popup v-model="dateTimePickerOpts.show" round position="bottom">
          <van-datetime-picker
              type="year-month"
              title="选择查询日期"
              v-model="dateTimePickerOpts.vModel"
              :min-date="dateTimePickerOpts.minDate"
              :max-date="dateTimePickerOpts.maxDate"
              :formatter="dateTimePickerOpts.formatter"
              @confirm="handleOnDateConfirm"
              @cancel="dateTimePickerOpts.show = false"
          />
        </van-popup>
        <p class="money-charts">提现 <span>{{ money.withdraw }}</span> 总收入 <span>{{ money.total }}</span></p>
      </div>
    </div>
    <page-container class="running-water-scroller">
      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          style="position: relative;z-index: 1"
      >
        <template #loading>
          {{ pagination.pageNum < 2 ? '' : '加载中...' }}
        </template>

        <!-- 更多列表加载 -->
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <!-- 任务 -->
          <div
              v-for="item of dataList"
              :key="item.id"
              class="running-water-item"
              :class="{
                'add-money': !item.isWithdraw,
                'deduct-money': item.isWithdraw,
              }"
          >
            <div class="money-container">
              <div class="money-title-item">{{ item.title }}</div>
              <div class="money-commission-item">
                <div class="commission-type-tag">{{ commissionTypeMap[item.commission] }}</div>
              </div>
              <div class="money-item">{{ item.money }}</div>
            </div>
            <p>{{ item.time }}</p>
          </div>
        </van-list>
      </van-pull-refresh>
    </page-container>
  </div>
</template>

<script>
import moment from 'moment';
import { getCommissionTestApi, getRunningWaterPageDataTestApi } from '@/test/runningWater';

export default {
  name: 'runningWater',
  data() {
    return {
      lineWidth: null,
      commissionTypeList: [],
      dateTimePickerOpts: {
        show: false,
        vModel: new Date(),
        minDate: moment().add(-12, 'month').toDate(),
        maxDate: new Date(),
        formatter(type, val) {
          if (type === 'year') {
            return `${val}年`;
          } else if (type === 'month') {
            return `${val}月`;
          }
          return val;
        },
      },

      searchForm: {
        commissionActiveIndex: 0,
        time: moment().format('YYYY-MM'),
      },

      money: {
        withdraw: 8888,
        total: 99999,
      },

      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    commissionTypeMap() {
      return [{}, ...this.commissionTypeList].reduce((map, type) => {
        let { id, name } = type;
        map[id] = name;
        return map;
      });
    },
    allCommissionTypeList() {
      let list = this.commissionTypeList;
      let commissionTypeList = Array.isArray(list) ? list : [];
      return [{ name: '全部', id: '' }, ...commissionTypeList];
    },
    searchTimeText() {
      let date = this.searchForm.time;
      return moment(date).format('YYYY年M月');
    },
    formatSearchForm() {
      let { searchForm, allCommissionTypeList } = this;
      let commissionActiveIndex = searchForm.commissionActiveIndex;
      let commission = allCommissionTypeList[commissionActiveIndex].id;
      return {
        commission,
        time: searchForm.time,
      };
    },
  },
  created() {
    getCommissionTestApi().then(res => {
      this.commissionTypeList = Array.isArray(res) ? res : [];
      this.$nextTick(() => {
        this.$watch('searchForm.commissionActiveIndex', this.setActiveWidth, { immediate: true });
      });
    });
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        formatSearchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getRunningWaterPageDataTestApi({
        ...formatSearchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    setActiveWidth() {
      let { commissionActiveIndex: index } = this.searchForm;
      let $commissionTabs = this.$refs.commissionTabs.$el;
      let $tabs = $commissionTabs.getElementsByClassName('van-tab');

      if (!$tabs[index]) return;

      this.lineWidth = $tabs[index]
          .getElementsByClassName('van-tab__text')[0]
          .getBoundingClientRect().width;
    },
    handleOnDateConfirm(date) {
      this.searchForm.time = moment(date).format('YYYY-MM');
      this.dateTimePickerOpts.show = false;
      this.onRefresh();
    },
  },
};
</script>

<style lang="less" scoped>
@van-tab-height: 60px;
@date-search-height: 42px;
@search-form-pdb: 3px;
@top-height: @van-tab-height + @date-search-height + @search-form-pdb;
.running-water-container {
  .search-form {
    padding: 0 16px @search-form-pdb 16px;
    background-color: #fff;

    /deep/ .van-tabs {
      background-color: red;

      .van-tabs__wrap {
        height: @van-tab-height;
      }

      .van-tab {
        line-height: @van-tab-height;
        z-index: 2;
        color: #333;
      }

      .van-tabs__nav--line {
        padding-bottom: 0;
      }

      .van-tabs__line {
        padding: 0 12px;
        height: 30px;
        background: #E3E3E3;
        border-radius: 15px;
      }
    }

    .date-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px;
      height: @date-search-height;
      background: #f5f6f8;

      .date-search-item {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 14px;
        color: #333;

        .van-icon {
          margin-left: .2em;
        }
      }

      .money-charts {
        font-size: 12px;
        color: #999;

        & > span {
          &:before {
            content: '￥';
          }
        }
      }
    }
  }

  .running-water-scroller {
    top: @top-height;

    .running-water-item {
      box-sizing: border-box;
      position: relative;
      z-index: 1;
      padding: 23px 17px 19px 65px;
      background-color: #fff;

      & + .running-water-item {

        &:before {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 65px;
          right: 0;
          border-top: 1px solid #eaeaea;
        }
      }

      &.add-money {
        background: url("../../assets/runningWater/add-money.svg") 17px center no-repeat #fff;
        background-size: 32px;
      }

      &.deduct-money {
        background: url("../../assets/runningWater/deduct-money.svg") 17px center no-repeat #fff;
        background-size: 32px;
      }

      .money-container {
        display: flex;
        align-items: center;

        .money-title-item {
          max-width: 33.33333%;
          font-weight: bold;
          font-size: 15px;
          color: #333333;
        }

        .money-commission-item {
          padding-left: 10px;

          .commission-type-tag {
            box-sizing: border-box;
            padding: 2px 9px;
            line-height: 14px;
            font-size: 12px;
            color: @primary-color;
            border: 1px solid @primary-color;
            border-radius: 9px;
          }
        }

        .money-item {
          flex: 1;
          flex-shrink: 0;
          padding-left: 10px;
          min-width: 18%;
          text-align: right;
          font-size: 15px;
          font-weight: bold;
          color: #171717;
        }
      }
    }
  }
}
</style>
